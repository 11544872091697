<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <!-- stepper -->
    <v-stepper alt-labels v-model="step">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="step > 1">{{ $t("stepper.level1") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2" :complete="step > 2">
          {{ $t("stepper.level2") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" :complete="step > 3">{{ $t("stepper.level3") }}
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="4" :complete="step > 4">
          {{ $t("stepper.level4") }}
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="5" :complete="step > 5">
          {{ $t("stepper.level5") }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-form  @submit.prevent="handleSubmit(onSubmit)">
      <br />

      <!--step 1 -->
      <v-row v-if="step == 1" justify="center">
        <v-col cols="12" sm="6" md="3" align-self="auto"> </v-col>
      </v-row>

      <v-row v-if="step == 1 && !registrationOpen()" justify="center">
        <v-col cols="12" sm="6" md="3" align-self="auto" style="text-align:center; box-shadow:0 0 10px 1px rgba(0, 0, 0, 0.1); border-radius: 1rem; min-height: 10rem;display:flex;align-items: center;justify-content: center;">ההרשמה סגורה כרגע</v-col>
      </v-row>

      <v-row v-if="step == 1 && registrationOpen()" justify="center">
        <v-col cols="12" sm="6" md="3">
          <ValidationProvider rules="" vid="identityType">
            <v-radio-group v-model="identityType" row>
              <v-radio :label="$t('labels.identity_number')" value="id"></v-radio>
              <v-radio :label="$t('labels.passport_number')" value="passport"></v-radio>
            </v-radio-group>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row v-if="step == 1 && identityType == 'id' && registrationOpen()" justify="center">
        <v-col cols="12" sm="6" md="3">
          <ValidationProvider name="id" rules="israeliId|required_if:identityType,id" v-slot="{ errors }">
            <v-text-field v-model="form.id" :label="$t('labels.identity_number')"
              :placeholder="$t('placeholders.identity_number')" :error-messages="errors"></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row v-if="step == 1 && identityType == 'passport' && registrationOpen()" justify="center">
        <v-col cols="12" sm="6" md="3">
          <ValidationProvider rules="required_if:identityType,passport" v-slot="{ errors }">
            <v-text-field v-model="form.passport" :label="$t('labels.passport_number')"
              :placeholder="$t('placeholders.passport_number')" :error-messages="errors"></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>

      <!--step 2 -->
      <v-row v-if="step == 2" justify="center">
        <v-col cols="12" sm="6" md="3">
          <v-subheader> {{ $t("subheaders.activity_selection") }}</v-subheader>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-combobox v-model="selectedActivity" :items="activities" :item-text="activityTitle"
              @input="getActivityOrders" clearable outlined :loading="loading.act" :error-messages="errors" >
            </v-combobox>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="step == 2 && selectedActivity != null" justify="center">
        <v-col cols="12" sm="6" md="3">
          <v-subheader>
            {{ $t("subheaders.order_selection") }}
          </v-subheader>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-combobox v-model="selectedOrder" :items="orders" :item-text="orderTitle" clearable outlined
              :loading="loading.ord" :error-messages="errors"></v-combobox>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row v-if="step == 2 && !validRegistrationByOrder() && selectedActivity != null" justify="center">
        <v-col cols="12" sm="6" md="3" align-self="auto" style="text-align:center; box-shadow:0 0 10px 1px rgba(0, 0, 0, 0.1); border-radius: 1rem; min-height: 10rem;display:flex;align-items: center;justify-content: center;">ההרשמה סגורה כרגע</v-col>
      </v-row>

      <!--Chevron fields-->
      <v-row v-if="step == 2 && selectedActivity != null && form.chev_trainee" justify="center">
        <v-col cols="12" sm="6" md="3">
          <v-subheader>
            {{ $t("subheaders.chev_nationality") }}
          </v-subheader>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-select v-model="form.chev_nationality" :items="nationality"  clearable outlined :loading="loading.ord"
              :error-messages="errors"></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row v-if="step == 2 && selectedActivity != null && form.chev_trainee" justify="center">
        <v-col cols="12" sm="6" md="3">
          <v-subheader>
            {{ $t("subheaders.chev_site") }}
          </v-subheader>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-select v-model="form.chev_site" :items="sites" clearable outlined :loading="loading.ord"
              :error-messages="errors"></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>

      <!--step 3 -->
      <v-container v-if="step == 3">
        <v-row justify="center" v-if="locale != 'en'">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-text-field v-model="form.fName_heb" :label="$t('labels.first_name')"
                :placeholder="$t('placeholders.first_name')" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row justify="center" v-if="locale != 'en'">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-text-field v-model="form.lName_heb" :label="$t('labels.last_name')"
                :placeholder="$t('placeholders.last_name')" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider :rules="validationRules('english_name')" v-slot="{ errors }">
              <v-text-field v-model="form.fName_en" :label="$t('labels.first_name_en')"
                :placeholder="$t('placeholders.first_name_en')" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider :rules="validationRules('english_name')" v-slot="{ errors }">
              <v-text-field v-model="form.lName_en" :label="$t('labels.last_name_en')"
                :placeholder="$t('placeholders.last_name_en')" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row v-if="step == 3" justify="center">
          <v-col cols="12" sm="6" md="3">
            <p>{{ $t("labels.phone") }}</p>
            <ValidationProvider :rules="validationRules('phone')" v-slot="{ errors }">
              <vue-tel-input v-model="phone"  mode="international" dir="ltr" defaultCountry="IL" @validate="phoneObject"></vue-tel-input>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider :rules="validationRules('email')" v-slot="{ errors }">
              <v-text-field v-model="form.email" :label="$t('labels.email')" :placeholder="$t('placeholders.email')"
                :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider :rules="validationRules('year_of_birth')" v-slot="{ errors }">
              <v-select v-model="form.year_of_birth" :items="years" :label="$t('labels.year_of_birth')"
                :error-messages="errors"></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider :rules="validationRules('gender')" v-slot="{ errors }">
              <v-select  v-model="form.gender" :items="[$t('lists.gender.male'),$t('lists.gender.female')]" :label="$t('labels.gender')"
                :error-messages="errors"></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>

      <!--step 4-->
      <v-container v-if="step == 4">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider :rules="validationRules('profile_img')" v-slot="{ errors }">
              <v-file-input v-model="profile_image" :label="$t('labels.profile_picture')" :error-messages="errors"
                prepend-icon="mdi-camera"></v-file-input>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row v-if="previewUrl != null" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-img max-height="200" max-width="200" :src="previewUrl"></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!--step 5-->

      <v-row v-if="step == 5" justify="center">
        <v-col cols="12" sm="6" md="3">
          <ValidationProvider rules="checkbox" v-slot="{ errors }">
            <v-checkbox v-model="termsCheckbox1" :error-messages="errors">
              <template v-slot:label>
                {{ $t("terms_agreement_checkbox.text_part") }} &nbsp;
                <a href="#" @click.stop.prevent="
                  showTermsDialog(
                    $t('dialog.terms_title_part'),
                    $t('dialog.terms_body_part')
                  )
                ">{{ $t("terms_agreement_checkbox.link_text_part") }}</a>
              </template>
            </v-checkbox>
          </ValidationProvider>

          <ValidationProvider rules="checkbox" v-slot="{ errors }">
            <v-checkbox v-model="termsCheckbox2" :error-messages="errors">
              <template v-slot:label>
                {{ $t("terms_agreement_checkbox.text_health") }} &nbsp;
                <a href="#" @click.stop.prevent="
                  showTermsDialog(
                    $t('dialog.terms_title_health'),
                    $t('dialog.terms_body_health')
                  )
                ">{{ $t("terms_agreement_checkbox.link_text_health") }}</a>
              </template>
            </v-checkbox>
          </ValidationProvider>

          <v-checkbox v-model="termsCheckbox3">
            <template v-slot:label>
              {{ $t("terms_agreement_checkbox.text_filming") }} &nbsp;
              <a href="#" @click.stop.prevent="
                showTermsDialog(
                  $t('dialog.terms_title_fliming'),
                  $t('dialog.terms_body_filming')
                )
              ">{{ $t("terms_agreement_checkbox.link_text_filming") }}</a>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <!--buttons-->
      <br />
      <v-row justify="center" class="my-10">
        <v-btn v-if="step != 1" elevation="2" @click="prevStep" class="mx-2">{{
            $t("buttons.prev")
        }}</v-btn>

        <!-- <v-btn
          v-if="step != totalSteps"
          elevation="2"
          @click="nextStep"
          class="mx-2"
          >{{ $t("buttons.next") }}</v-btn
        > -->

        <v-btn class="mx-2" elevation="2" :disabled="!registrationOpen()" :loading="loading.send_btn" type="submit">{{ submitBtnText() }}</v-btn>
      </v-row>

      <Dialog />
    </v-form>
  </ValidationObserver>
</template>

<script>
import axios from "axios";
import Dialog from "./Dialog";
import { VueTelInput } from "vue-tel-input";
import i18n from "../i18n";
import { ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {  } from 'vee-validate';
import { required, email, required_if ,min} from "vee-validate/dist/rules";
import { checkbox, israeliId } from "../validations";
import formData from "form-data";
import moment from "moment";

let isValidNumber = false;

extend("required", {
  ...required,
  message: "This field is required",
});
extend("email", {
  ...email,
  message: "email is not valid",
});
extend("required_if", {
  ...required_if,
  message: "This field is required",
});
extend('min', {
  ...min,
  message: "Phone is not valid",
});
extend("validNumber", {validate:() => isValidNumber, message: "Phone is not valid"});
extend("checkbox", checkbox);
extend("israeliId", israeliId);
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  /*
    API
    1 - get activities list 
    2 - get orders list accrding to chosen activity
    3 - check if customer exists, if yes populate fields
    4 - next Btn -> add new activity trainee (add customer if not exists)
    5 - next Btn -> upload trainee image
    */
  components: {
    Dialog,
    VueTelInput,
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    results: null,
    years: [],
    gender_options: [],
    identityType: "id",
    menu: false,
    termsCheckbox1: false,
    termsCheckbox2: false,
    termsCheckbox3: false,
    activities: [],
    orders: [],
    loading: {
      act: true,
      ord: false,
      send_btn: false,
    },
    nationality: ["Expat","Local"],
    sites: ["LOS", "LPP", "Tamar", "Mari-B", "AOT", "ECS", "Office","ISY", "L3GL","Shore Base", "Central Warehouse"],
    depts: ["Operations", "Maintenance", "Projects", "EHS", "Supply Chain","Subsea", "Engineering", "Security", "Logistics"]
  }),

  methods: {
    phoneObject: function(object) {
      if (object.valid === true) {
        this.isValid = true;
        isValidNumber = true;
      } else {
        this.isValid = false;
        isValidNumber = false
      }
    },
    onSubmit: function () {
      if (this.step == 5) {
        this.sendForm();
      } else {
        this.nextStep();
      }
    },
    nextStep: function () {
      let step = this.step;
      this.step = step + 1;
    },
    prevStep: function () {
      let step = this.step;
      this.step = step - 1;
    },
    sendForm: async function () {
      if(this.form.gender === "Male"){
        this.form.gender = "זכר";
      }else if (this.form.gender === "Female"){
        this.form.gender = "נקבה";
      }
      this.loading.send_btn = true;
      let isEmployee = false;
      let zid = this.form.customer_zid; //customer or employee zid
      if (this.form.employee_zid != null) {
        isEmployee = true;
      }

      try {
        let traineeResult = await this.postTrainee(this.form, isEmployee);
        if (isEmployee) {
          zid = this.form.employee_zid;
        }
        else {
          zid = traineeResult.customer_zid
        }
        if (zid && this.form.profileImg) {
          try {
            await this.postProfileImage(
              this.form.profileImg,
              zid,
              isEmployee
            );
          } catch (err) {
            alert("Image Upload Error: " + err);
          }
        }
        this.$swal(
          this.$t("alerts.register_success_title"),
          "",
          "success"
        ).then(() => {
          window.location = "https://reg.rescue-1.com";
        });
        this.loading.send_btn = false;
      } catch (err) {
        this.$swal(
          this.$t("alerts.register_failed_title"),
          this.$t("alerts.register_failed_body"),
          "error"
        );
        this.loading.send_btn = false;
      }
    },
    postTrainee: (form, isEmployee) => {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_BASE_URL + `/trainee?employee=${isEmployee}`;
        axios
          .post(url, form)
          .then((response) => {
            let customer_zid = response.data.customer_zid;
            let trainee_zid = response.data.trainee_zid
            let result = { customer_zid, trainee_zid }
            resolve(result);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    postProfileImage: (file, zid, isEmployee) => {
      return new Promise((resolve, reject) => {
        let form = new formData();
        form.append("image_file", file);
        let url = `${process.env.VUE_APP_BASE_URL}/profile-image?zid=${zid}&employee=${isEmployee}`;
        axios
          .post(url, form)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //show object title field - combobox
    activityTitle: (item) => {
      if (i18n.locale == "en") {
        return item.register_title_en;
      }
      return item.register_title_heb;
    },
    orderTitle: (item) => {
      if (i18n.locale == "en") {
        return item.register_title_en;
      }
      return item.register_title_heb;
    },
    getActivityOrders: async function () {
      this.loading.ord = true;
      this.selectedOrder = null;
      await this.$store.dispatch(
        "getActivityOrders",
        this.selectedActivity.activity.ID
      );
      this.orders = [];
      let activityOrders = this.$store.getters.activity_orders;
      console.log(this);
      activityOrders.forEach((element) => {
        this.orders.push(element);
      });
      if (this.orders.length == 1) {
        this.selectedOrder = this.orders[0];
      }
      this.loading.ord = false;
      
    },
    getCustomer: async function () {
      //first - check if it's employee
      await this.$store.dispatch("fetchEmployee");
      //if not employee -> check if it's an existing customer
      if (!this.form.employee_zid) {
        await this.$store.dispatch("fetchCustomer");
      }
    },
    showTermsDialog: function (title, body) {
      let value = {
        title: title,
        body: body,
        show: true,
      };
      this.$store.dispatch("setDialog", value);
    },
    //form validation
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submitBtnText() {
      if (this.step == this.totalSteps) {
        return this.$t("buttons.send");
      }
      return this.$t("buttons.next");
    },
    registrationOpen(){
      return this.form?.registration_open;
    },
    validRegistrationByOrder(){
      return this.form?.valid_registration_by_order;
    },
    //return rules by activity setting
    validationRules(field) {
      switch (field) {
        case "phone":
          // return this.setting.length < 10 ? "required" : "";
          return this.setting.required_fields.phone ? "required|validNumber" : "";
        case "email":
          return this.setting.required_fields.email
            ? "email|required"
            : "email";
        case "profile_img":
          return this.setting.required_fields.profile_img ? "required" : "";
        case "english_name":
          return this.setting.required_fields.english_name ? "required" : "";
        case "year_of_birth":
          return this.setting.required_fields.year_of_birth ? "required" : "";
        case "gender":
          return this.setting.required_fields.gender ? "required" : "";
      }
    },
  },

  computed: {
    selectedActivity: {
      get() {
        return this.$store.state.form.activity;
      },
      set(value) {
        //update selected activity
        this.$store.dispatch("setActivity", value);
        //update activity setting
        let requiredFields = {
          phone: value["activity.phone_required"],
          email: value["activity.email_required"],
          profile_img: value["activity.profile_img_required"],
          english_name: value["activity.english_name_required"],
          year_of_birth: value["activity.year_of_birth_required"],
          gender: value["activity.gender_required"],
        };
        this.$store.dispatch("setRequiredFields", requiredFields);
      },
    },
    selectedOrder: {
      get() {
        return this.$store.state.form.order;
      },
      set(value) {
        let total = 0;
        console.log(this.$store.state.form);
        console.log(this.$store.state.activity_days);
        const maxTrainees =  this.$store.state.activity_days.find((act)=> act.ID === this.$store.state.form.activity.ID)?.["activity.max_trainees"];
        const activityOrders = this.$store.state.activity_orders.filter((order) => order.ID !== value.ID);
        const currentActivtyOrder = this.$store.state.activity_orders.filter((order) => order.ID === value.ID);

        activityOrders?.forEach((order) => {
          const reservedTraineesCount = +order.reserved_trainees_count;
          const numOfTrainees = +order.num_of_trainees;
          if(reservedTraineesCount > numOfTrainees){
            total += reservedTraineesCount;
          }else{
            total += numOfTrainees;
          }
        })

        if(currentActivtyOrder.length){ 
          console.log(+currentActivtyOrder[0].num_of_trainees);
          total+= +currentActivtyOrder[0].num_of_trainees;
        }

        console.log("total: " , total);
        console.log("maxTrainees: ", maxTrainees);

        if(maxTrainees - total <= 0){
          this.form.valid_registration_by_order = false;
          this.$store.dispatch("setOrder", null);
        }else{
          this.form.valid_registration_by_order = true;
          this.$store.dispatch("setOrder", value);
        }
      },
    },
    form: {
      get() {
        return this.$store.state.form;
      },
    },
    phone: {
      get() {
        return this.$store.state.form.phone;
      },
      set(value) {
        this.$store.dispatch("setPhone", value);
      },
    },
    profile_image: {
      get() {
        return this.$store.state.form.profileImg;
      },
      set(value) {
        this.$store.dispatch("setProfileImage", value);
      },
    },

    step: {
      get() {
        return this.$store.state.step;
      },
      set(value) {
        if (this.step == 1) {
          this.getCustomer();
        }
        this.$store.dispatch("setStep", value);
      },
    },
    totalSteps: {
      get() {
        return this.$store.state.totalSteps;
      },
    },
    locale: {
      get() {
        return this.$i18n.locale;
      },
    },
    setting: {
      get() {
        return this.$store.state.form_setting;
      },
    },
    previewUrl: {
      get() {
        return this.$store.state.image_preview_url;
      },
    },
  },
  async mounted() {
    //populate birth years
    let y = new Date().getFullYear() - 16;
    let minYear = 1940;
    do {
      this.years.push(y.toString());
      y--;
    } while (y > minYear);
    this.$store.dispatch("setYears", this.years);
    //populate gender
    this.gender_options.push(this.$t("lists.gender.male"));
    this.gender_options.push(this.$t("lists.gender.female"));
    
    //get today activities
    var todayDate = new Date();
    var date =
      todayDate.getDate() +
      "/" +
      (todayDate.getMonth() + 1) +
      "/" +
      todayDate.getFullYear();

    if (this.$route.query.date) {
      try {
        date = new Date(this.$route.query.date);
        date = moment(date).format("DD/MM/YYYY");
      } catch (error) {
        console.log(error);
      }
    }
    this.$store.dispatch("setDate", date);
    let payload = new Map();
    payload.set("date", date);
    payload.set("activity", this.$route.query.act)
    await this.$store.dispatch("getActivityDays", payload);
    let activityDays = this.$store.getters.activity_days;
    activityDays.forEach((element) => {
      this.activities.push(element);
    });

    if (this.$route.query.lan != null && this.$route.query.lan == "en") {
      this.$i18n.locale = "en";
      this.$vuetify.rtl = false;

    }
    this.loading.act = false;
  },
  async created() { },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css">
</style>